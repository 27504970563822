import React, { useEffect } from "react";

const RedirectPage = (graphql) => (
  <a
    href={graphql.pageContext.url}
    onLoad={useEffect(() => {
      const isBrowser =
        window && window.location.replace(`${graphql.pageContext.url}`);
    }, [])}
  >
    loading {graphql.pageContext.key}
  </a>
);

export default RedirectPage;
